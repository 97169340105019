<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Add Product</strong> </CCardHeader>
          <CCardBody v-if="isLoading" class="text-center">
            <CSpinner color="primary" size="lg" />
          </CCardBody>
          <CForm autocomplete="off" @submit.prevent="submit" v-else>
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <div class="mb-3 text-center">
                    <input
                      class="d-none"
                      type="file"
                      @change="selectFile"
                      accept="image/*"
                      ref="image"
                    />
                    <img
                      @click="$refs.image.click()"
                      height="150px"
                      :src="imageUrl"
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <div class="col-md-12 mb-3">
                  <v-select
                    @open="onOpen"
                    @close="onClose"
                    @search="triggerSearch"
                    :options="product_models"
                    label="name"
                    v-model="selected_product_model"
                    placeholder="Search and select product model"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name }} &#8594;
                      <CIcon name="ProductCategory" />
                      <span class="badge badge-light"
                        >{{ option.product_category.name }}
                      </span>
                      &#8594; <CIcon name="Brand" />
                      <span class="badge badge-light"
                        >{{ option.brand.name }}
                      </span>
                      &#8594; <CIcon name="ProductGroup" />
                      <span class="badge badge-light"
                        >{{ option.product_group.name }}
                      </span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.name }} &#8594;
                      <CIcon name="ProductCategory" />
                      <span class="badge badge-light"
                        >{{ option.product_category.name }}
                      </span>
                      &#8594; <CIcon name="Brand" />
                      <span class="badge badge-light"
                        >{{ option.brand.name }}
                      </span>
                      &#8594; <CIcon name="ProductGroup" />
                      <span class="badge badge-light"
                        >{{ option.product_group.name }}
                      </span>
                    </template>
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!selected_product_model"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                    <template #list-footer>
                      <li
                        ref="load"
                        class="loader text-center"
                        v-show="page && isFetching && !selected_product_model"
                      >
                        <CSpinner color="info" size="sm" />
                        <span class="ml-2 text-muted"
                          >fetching product models...</span
                        >
                      </li>
                      <li class="loader text-center" v-show="isSearching">
                        <CSpinner color="info" size="sm" />
                        <span class="ml-2 text-muted">searching...</span>
                      </li>
                    </template>
                    <span slot="no-options">
                      <li
                        class="loader text-center"
                        v-show="
                          !isSearching && !product_models.length && !isFetching
                        "
                      >
                        <CIcon name="emojiSad" />
                        <span class="ml-2 text-muted"
                          >nothing found!</span
                        >
                      </li>
                    </span>
                  </v-select>
                </div>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Product Name"
                    v-model="name"
                    placeholder="Enter Product name..."
                    required
                    @keyup="check_name"
                    :addInputClasses="name_invalid ? 'is-invalid' : null"
                    :isValid="name ? true : null"
                  />
                </CCol>
                <CCol sm="3">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Stock Notify"
                    v-model="stock_notify"
                    placeholder="Notify when stock quantity is..."
                    required
                    min="0"
                    @change="check_stock_notify"
                    :addInputClasses="
                      stock_notify_invalid ? 'is-invalid' : null
                    "
                    :isValid="stock_notify ? true : null"
                  />
                </CCol>
                <CCol sm="3">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Sale Price"
                    v-model="price"
                    placeholder="Enter product sale price..."
                    required
                    min="0"
                    @change="check_price"
                    :addInputClasses="
                      price_invalid ? 'is-invalid' : null
                    "
                    :isValid="price ? true : null"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea  rows="6"
                    label="Description"
                    v-model="description"
                    placeholder="Enter description..."
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-right">
                <CButton
                  size="sm"
                  color="primary"
                  type="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Submit</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductCreate",
  data() {
    return {
      observer: null,
      searchKeyword: null,
      searchQuery: null,
      name: null,
      price: 0,
      selected_product_model: this.product_models,
      description: null,
      stock_notify: 0,
      name_invalid: false,
      price_invalid: false,
      image: null,
      changedImage: null,
      stock_notify_invalid: false, 
    };
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("products/getProductModels");
    this.searchQuery = _.debounce(
      () => this.fetch_search_items(this.searchKeyword),
      500
    );
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  watch: {
    searchKeyword: function (val) {
      this.searchQuery(val);
    },
    selected_product_model: function(val){
      if(val === null) {
        this.$nextTick();
        this.observer.observe(this.$refs.load);
        this.fetch_search_items(" ")
      }
    }
  },
  computed: {
    ...mapState({
      product_models: (state) => state.products.product_models,
      product_model_fetch_pagination: (state) =>
        state.products.product_model_fetch_pagination,
      isSaving: (state) => state.products.isSaving,
      isSearching: (state) => state.products.isSearching,
      isLoading: (state) => state.products.isLoading,
      isFetching: (state) => state.products.isFetching,
    }),
    page() {
      return this.product_model_fetch_pagination.current >=
        this.product_model_fetch_pagination.last
        ? false
        : this.product_model_fetch_pagination.current + 1;
    },
    imageUrl() {
      return this.changedImage
        ? this.changedImage
        : "http://laptopplaza.duckpeon.com/uploads/no-photo.png";
    },
  },
  methods: {
    triggerSearch(keyword) {
      this.searchKeyword = keyword;
    },
    async onOpen() {
      if (!this.selected_product_model) {
        this.$store.commit("products/fetching", true);
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      } else {
        this.observer.disconnect();
      }
    },
    onClose() {   
      this.observer.disconnect();
    },
    infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting) {
        if (this.page && !this.selected_product_model) {
          this.$store.dispatch("products/getProductModels", this.page);
        }
      }
    },
    fetch_search_items(keyword) {
      if (typeof keyword === "string" || !keyword) {
        if (keyword.trim().length == 0) {    
          if (this.selected_product_model === null) {
             this.$store.commit("products/fetching", true);
            this.$store.dispatch("products/getProductModels");
          }
        } else {
          this.$store.commit("products/fetching", false);
          this.$store.dispatch("products/fetch_search_items", keyword);
        }
      }
    },
    selectFile(event) {
      this.image = event.target.files[0];
      this.changedImage = URL.createObjectURL(this.image);
    },
    check_name() {
      if (!this.name) {
        this.name_invalid = true;
      } else {
        this.name_invalid = false;
      }
    },
    check_stock_notify() {
      if (!this.stock_notify) {
        this.stock_notify_invalid = true;
      } else {
        this.stock_notify_invalid = false;
      }
    },
    check_price() {
      if (!this.price) {
        this.price_invalid = true;
      } else {
        this.price_invalid = false;
      }
    },
    checkForm() {
      if (!this.name) {
        this.check_name();
      } else {
        return true;
      }
      if (!this.stock_notify) {
        this.check_stock_notify();
      } else {
        return true;
      }
      if (!this.price) {
        this.check_price();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm() == true) {
        const data = new FormData();
        if (this.image) {
          data.append("img", this.image);
        }
        data.append("name", this.name);
        if (this.description) {
          data.append("description", this.description);
        } else {
          data.append("description", "No Description");
        }
        data.append("price", this.price);
        data.append("product_model_id", this.selected_product_model.id);
        data.append("product_group_id", this.selected_product_model.product_group.id);
        data.append("brand_id", this.selected_product_model.brand.id);
        data.append("product_category_id", this.selected_product_model.product_category.id);
        data.append("stock_notify", this.stock_notify);
        this.$store.dispatch("products/saveProduct", data).then((response) => {
          this.clear_form();
        });
      } else {
        this.checkForm();
      }
    },
    clear_form() {
      this.description = null;
      this.name = null;
      this.selected_product_model = null;
      this.price = 0;
      this.stock_notify = 0;
    },
  },
};
</script>
 